// dependencies
import { useContext, useEffect } from 'react';
import { ThemeContext } from '../ThemeContext';

// assets
import Sparkle from "../assets/icons/sparkle.svg";
import SparkleDark from "../assets/icons/sparkle-dark.svg";

// components
import Archive from "./home/Experience";
import Work from "./home/Work";
import CTA from "./shared/CTA";

function Home() {
    const { darkTheme } = useContext(ThemeContext);

    useEffect(() => {
        const sparkleImages = document.querySelectorAll('img[alt="Sparkle"]');
        if (darkTheme) {
            sparkleImages.forEach(img => img.src = SparkleDark);
        } else {
            sparkleImages.forEach(img => img.src = Sparkle);
        }
    }, [darkTheme]);

    return (
        <main>
            <section className="work-hero">
                <header className="mb-2">
                    <div className="name-title mb-3">
                        <h1>Jihan Duerme</h1>
                        <img src={Sparkle} alt="Sparkle" />
                        <h1>UI/UX Designer & Developer</h1>
                    </div>
                    <h1 className="main-title">Websites and products that look good and are built precisely for your needs</h1>
                </header>
                <p>I have collaborated with SaaS companies and digital marketing agencies for over a decade to create compelling digital experiences that resonate with users and effectively meet business goals</p>
                <a href="https://jihanduerme.com/about" className="button">About Me</a>
            </section>
            <section className="work-sections">
                <Work />
                <Archive />
            </section>
            <CTA />
        </main>
    );
};

export default Home;